@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800&display=swap');
@import 'third-party/font-awesome';
@import 'third-party/bootstrap';
@import 'third-party/material';

html, body { height: 100%; }
body { 
	margin: 0;
	font-family: 'Roboto', sans-serif;
 }
 body::-webkit-scrollbar{
	 display: none;
 }

mat-icon {
  // Override Bootstrap reboot styles
  svg {
    vertical-align: baseline;
  }
}

// Include Color Palettes
@import './palettes/palette-1/root';
@import './palettes/palette-2/root';

// Just to debug responsive stuff
@import 'mixins/debug-breakpoints';

button:focus{
	outline:3px red !important;
}
.londrina{
	font-family: 'Roboto', cursive;
	font-weight:700;
}
/*
input:focus{
	outline:none !important;
	    border-color: #d1f2a6 !important;
    box-shadow: 0 0 0 0.2rem rgb(113 176 29 / 17%) !important;
}
*/

.mat-drawer-inner-container{
	overflow-x:hidden !important;
}
th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell
{
	border-bottom-width:0 !important;
}
.carousel-slide{
    border-radius:5px !important;
}
.carousel{
	border-radius:5px !important;
}

.form-control:focus
{
	
}

.pagecontent-wrapper{
padding:1rem;
 }


.admin-main-sidenav{
  box-shadow: 0px 0px 9px 1px #ddd !important;
}

.modal-content
{
	border:none !important;
}

.btn-success{
	background-color:#71B01D !important;
	border-color:#71B01D !important;
}
.text-primary{
	color: #4891e0!important;
}

.selectnoborder > .ng-select-container{
	border:none !important;
	border-bottom: 1px solid #949494 !important;
	border-radius:0 !important;
	background: transparent !important;
} 
.selectnoborder input{
	border-color:none !important;
	box-shadow:none !important;
}
.selectnoborder > .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
	box-shadow:none !important;
}

.selectnoborder .ng-value-container{
	padding-left:0 !important;
}
.selectnoborder .ng-input
{
	padding-left:0 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
	box-shadow:none !important;
}

.btn-primary{
	background-color:#4891E0 !important;
	border-color:#4891E0 !important;
}

.btn-outline-success:hover {
    color: #fff !important;
    background-color: #71B01D !important;
    border-color: #71B01D !important;
}

.btn-outline-success {
    color: #71B01D !important;
    border-color: #71B01D !important;
}

.cdk-overlay-container{
	z-index:1051 !important;
}


.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #4891E0 !important;
    background-color: #4891E0 !important;
}

.selectwithadd .ng-option:first-child{
	background: #f6ffea !important;
    color: #71B01D !important;
    font-weight: 600 !important;
    //border-bottom: 1px solid #c0d3ab !important;
}

.select_input_noborder input{
	border-color:none !important;
	box-shadow:none !important;
}


.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #4891E0 !important;
    border-color: #4891E0 !important;
}

.btn-outline-primary {
    color: #4891E0 !important;
    border-color: #4891E0 !important;
}

.text-successs{
	color:#71B01D !important;
}

.groupstoreselector .ng-select-container{
	border-radius:30px !important;
}